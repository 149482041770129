import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Container from "../components/Container";
import Layout from "../components/Layout";
import LinkButton from "../components/LinkButton";
import Seo from "../components/Seo";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "page-404"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function NotFound() {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={t("page-404:title")} />
            <Container>
                <StaticImage src="../../assets/images/404.webp" alt="404" />
                <p className="text-center">{t("page-404:notFoundMessage")}</p>
                <div className="flex mt-12 justify-center">
                    <LinkButton to="/" text="Home" />
                </div>
            </Container>
        </Layout>
    );
}
